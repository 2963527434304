import React from "react";
import { Container, Jumbotron } from "react-bootstrap";
import "./css/hero.css";

const Hero = ({ heroTitle, heroText }) => {
  return (
    <Jumbotron className="hero">
      <Container>
        <h1 className="heading">{heroTitle}</h1>
      </Container>
    </Jumbotron>
  );
};

export default Hero;
