import React from "react";
import { Container } from "react-bootstrap";
import Layout from "../components/layout";
import "../components/css/layout_main.css";
import "../components/css/delivery.css";
import Hero from "../components/hero";

const DeliveryPage = () => {
  return (
    <Layout pageTitle="Oriental - Delivery">
      <Hero heroTitle="Delivery" heroText="" />
      <Container className="delivery-content">
      </Container>
    </Layout>
  );
};

export default DeliveryPage;
